<template>
    <div class="modal profile_modal schedule" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Scheduled Message</h1>
                <button class="close_btn" @click="closeModel()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="result_wpr new">
                    <div class="actions">
                        <ul class="left">
                            <li class="edit">
                                <label for="all-portal-chat-check" class="checkbox">
                                    <input type="checkbox" id="all-portal-chat-check" v-model="selectAll" @change="toggleSelectAll" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                            </li>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePageFilter">
                                Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="all-portal-chat-filter" />
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="search_area">
                                <input type="text" @input="isTyping = true" v-model.trim="params.search" placeholder="Search" />
                                <button class="search_btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li>
                            <li class="delete"  @click="handleDeleteScheduledSms()" v-if="allScheduledMessage.total"><i class="fas fa-trash-alt"></i></li>
                            <!-- <li class="list_info">
                                {{ allScheduledMessage.from ? allScheduledMessage.from : 0 }} - {{ allScheduledMessage.to ? allScheduledMessage.to : 0 }} of <span>{{ allScheduledMessage.total ? allScheduledMessage.total : 0 }}</span>
                            </li> -->
                        </ul>
                    </div>
                    <div v-if="loader"><line-loader /></div>
                    <div class="scroll_table">
                        <table class="show_header standard">
                            <thead>
                                <tr>
                                    <th class="selectbox"></th>
                                    <th class="userbox">Name</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Media</th>
                                    <th>Timezone</th>
                                    <th class="schedule">Schedule</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="allScheduledMessage.total">
                                <tr v-for="(schedule, s) in allScheduledMessage.data" :key="s">
                                    <td>
                                        <label :for="`all-portal-contact-${s}`" class="checkbox">
                                            <input type="checkbox" :id="`all-portal-contact-${s}`" :value="schedule.id" v-model="scheduleMessageIds" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </td>
                                    <td class="userbox" @click.stop="tableInfo($event)" v-click-outside="closeAllTableInfo">
                                        <div class="user_wpr">
                                            <div class="user_img">
                                                <img :src="schedule.contact ? schedule.contact.photo : '@/assets/images/logo.png'" @error="replaceByDefault" alt="Contact Image">
                                            </div>
                                            <h4>{{ schedule.contact ? schedule.contact.name : '' }}</h4>
                                        </div>
                                        <div class="dropdown_wpr">
                                            <ul>
                                                <li>
                                                    <label>Name:</label>
                                                    <div class="content">{{ schedule.contact ? schedule.contact.name : '-' }}</div>
                                                </li>
                                                <li>
                                                    <label>Email:</label>
                                                    <div class="content">{{ schedule.contact ? schedule.contact.email : '-' }}</div>
                                                </li>
                                                <li>
                                                    <label>Message:</label>
                                                    <div class="content">{{ schedule.message ? schedule.message : '-' }}</div>
                                                </li>
                                                <li>
                                                    <label>Media:</label>
                                                    <div class="content" v-if="schedule.media && schedule.media.length" v-html="getFileIconByUrl(schedule.media, uniqueId, '' )"></div>
                                                    <div class="content" v-else>-</div>
                                                </li>
                                                <li>
                                                    <label>Timezone:</label>
                                                    <div class="content">{{ schedule.time_zone }}</div>
                                                </li>
                                                <li>
                                                    <label>Status:</label>
                                                    <span class="tag" :class="schedule.in_queue == 1 ? 'success' : (schedule.in_queue == 2 ? 'failed' : '')">
                                                        {{schedule.in_queue == 1 ? 'Sending' : (schedule.in_queue == 2 ? 'Failed' : 'Waiting')}}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">
                                            <span class="email_add">{{ schedule.contact ? schedule.contact.email : '-' }}</span>
                                        </div>
                                    </td>
                                    <td class="sms"><div class="text_body">{{ schedule.message ? schedule.message : '-' }}</div></td>
                                    <td v-if="schedule.media && schedule.media.length">
                                        <div class="schedule_media" v-html="getFileIconByUrl(schedule.media, uniqueId, '' )"></div>
                                    </td>
                                    <td v-else>-</td>
                                    <td>{{ schedule.time_zone }}</td>
                                    <td class="schedule">{{ moment(schedule.schedule).format('ll | LT') }}</td>
                                    <td>
                                        <button class="success" v-if="schedule.in_queue == 1">Sending</button>
                                        <button class="failed" v-else-if="schedule.in_queue == 2">Failed</button>
                                        <button v-else>Waiting</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="8" class="px-4 text-center empty">
                                        <div class="empty_box">
                                        <img src="@/assets/images/empty_state.svg">
                                        <h4>No Records Found</h4>
                                    </div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ allScheduledMessage.from ? allScheduledMessage.from : 0 }} - {{ allScheduledMessage.to ? allScheduledMessage.to : 0 }} of {{ allScheduledMessage.total ? allScheduledMessage.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination pb-4 mt-4"  v-if="allScheduledMessage.total">
                    <pagination :range-size="0" v-model="params.page" :pages="allScheduledMessage.last_page" @update:modelValue="handlePaginationMessage" />
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModel()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid';

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Scheduled Portal Message',

        data () {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                },
                moment,
                isTyping: false,
                selectAll: false,
                uniqueId: 'id'+uuid.v4(),
                scheduleMessageIds: [],
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;

                    vm.params = {
                        page: 1,
                        per_page: 5,
                        search: '',
                    },

                    vm.getAllScheduledMessage(vm.params);
                }
            },

            'params.per_page' () {
                const vm = this;
                vm.params.page = 1;

                vm.getAllScheduledMessage(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getAllScheduledMessage(vm.params);
                        }
                    }
                }
            },

            'scheduleMessageIds' (ids) {
                const vm = this;

                if (vm.allScheduledMessage.data && Object.keys(vm.allScheduledMessage.data).length !== ids.length) {
                    vm.selectAll = false;
                } else if (vm.allScheduledMessage.data) {
                    vm.selectAll = true;
                }
            },
        },

        computed: mapState ({
            allScheduledMessage: state => state.portalChatModule.allScheduledMessage,
            loader: state => state.portalChatModule.allScheduledMessageLoader,
        }),

        methods: {
            ...mapActions ({
                getAllScheduledMessage: 'portalChatModule/getAllScheduledMessage',
                deleteScheduledMessage: 'portalChatModule/deleteScheduledMessage',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            togglePerPageFilter (value) {
                const vm = this;
                const filter = vm.$refs['all-portal-chat-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['all-portal-chat-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePaginationMessage (page) {
                const vm = this;

                vm.params.page = page;
                vm.getAllScheduledMessage(vm.params);
            },

            handleDeleteScheduledSms () {
                const vm = this;

                if (vm.scheduleMessageIds.length !== 0) {
                    const option = Helper.swalConfirmOptions('Are You Sure?', "You will not be able to recover these scheduled Message.", 'Yes')

                    option.preConfirm = function() {
                        return vm.deleteScheduledMessage({ ids: vm.scheduleMessageIds }).then((result) => {
                            vm.params.page        = 1;
                            vm.params.search      = '';
                            vm.scheduleMessageIds = [];
                            vm.selectAll          = false;

                            vm.getAllScheduledMessage(vm.params);
                        });
                    };

                    Swal.fire(option);
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one scheduled Messsage!', 'Okay')

                    Swal.fire(option);
                }
            },

            toggleSelectAll () {
                const vm = this;

                if (vm.selectAll) {
                    vm.allScheduledMessage.data.forEach((result) => {
                        vm.scheduleMessageIds.push(result.id);
                    });
                } else {
                    vm.scheduleMessageIds = [];
                }
            },

            getFileIconByUrl (image, uniqueId, contentType = '') {
                if (image.length) {
                    return Helper.getFileIconByUrl(image[0], uniqueId, contentType);
                }
            },

            replaceByDefault(e) {
                e.target.src = '@/assets/images/logo.png';
            },

            tableInfo(ev){
                const vm = this;
                const targetEl = ev.currentTarget.querySelector('.dropdown_wpr');

                if(targetEl.classList.contains('active')){
                    targetEl.classList.remove('active');
                } else{
                    vm.closeAllTableInfo();
                    targetEl.classList.add('active');
                }
            },

            closeAllTableInfo(){
                const allTargetEl = document.querySelectorAll('table .userbox .dropdown_wpr');

                allTargetEl.forEach(el => {
                    el.classList.remove('active');
                });
            }
        }
    }
</script>

<style scoped>
    .modal.profile_modal.schedule .modal_header {
        padding: 20px 30px 0;
    }
    .modal.profile_modal.schedule > .modal_container > .modal_body {
        padding: 15px 30px;
        flex-direction: column;
        background: #f5f5f5;
    }
    .scroll_table {
        overflow-x: auto;
    }
    .modal.profile_modal .result_wpr table{
        min-width: 1300px !important;
    }
    .modal.profile_modal .result_wpr .actions{
        min-width: auto !important;
    }
    .modal.schedule .modal_container {
        height: 640px;
    }

    .schedule .modal_header {
        padding: 20px 30px 0;
    }

    .schedule .modal_body {
        padding: 15px 30px;
        flex-direction: column;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .actions>ul li.delete {
        flex: 0 0 50px;
        cursor: pointer;
    }

    .actions>ul li.delete i {
        color: #f04438;
        margin: 0;
    }

    .actions>ul li .dropdown_wpr {
        max-height: 300px;
        overflow-y: scroll;
    }

    .actions>ul li .dropdown_wpr::-webkit-scrollbar {
        width: 4px;
    }

    .actions>ul li .dropdown_wpr:hover::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    }

    table.standard th {
        font-size: 13px;
        line-height: 16px;
        padding: 15px 12px;
    }

    table.standard td {
        font-size: 13px;
        line-height: 16px;
        padding: 18px 12px;
    }
    .result_wpr table th:last-child, .result_wpr table td:last-child{
        text-align: right;
    }
    table td.sms{
        max-width: 200px;
    }
    table td .text_body{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    table.standard td .user_wpr h4 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        letter-spacing: 0;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .schedule_media {
        width: 200px !important;
    }

    .result_wpr table td.userbox{
        position: relative;
    }
    .result_wpr table td.userbox .dropdown_wpr{
        left: -35px;
        width: 335px;
        max-height: 300px;
        overflow-y: auto;
        display: none;
    }
    .result_wpr table td.userbox .dropdown_wpr label{
        display: block;
        color: #121525;
        padding-bottom: 3px;
    }
    .result_wpr table td.userbox .dropdown_wpr .tag{
        display: inline-block;
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #fff7e8;
        border-radius: 10px;
        color: #f2a31e;
        margin-top: 3px;
    }
    .result_wpr table td.userbox .dropdown_wpr .tag.success{
        background: #e6ffe8;
        color: #00aa14;
    }
    .result_wpr table td.userbox .dropdown_wpr .tag.failed{
        background: #ffe9e9;
        color: #eb1414;
    }
    .result_wpr table td.userbox .dropdown_wpr::-webkit-scrollbar{
        width: 3px;
    }
    .result_wpr table td.userbox .dropdown_wpr::-webkit-scrollbar-thumb{
        border-radius: 2px;
        background: #dbdbdb;
    }

    @media(max-width: 767px){
        .result_wpr.new .actions > ul{
            flex-wrap: nowrap !important;
        }
        .result_wpr.new .actions > ul li.search_area{
            margin-left: auto;
        }
        .result_wpr.new .actions > ul li.search_area input[type=text]{
            width: 200px;
            height: 32px;
        }
    }
    @media(max-width: 499px){
        .modal.profile_modal.schedule .modal_header{
            padding: 20px 15px 0 15px;
        }
        .modal.profile_modal.schedule > .modal_container > .modal_body,
        .modal.profile_modal.schedule .modal_footer {
            padding: 15px;
        }
        .result_wpr.new .actions > ul{
            width: 100%;
        }
        .result_wpr.new .actions > ul.right{
            margin-top: 10px;
        }
        .result_wpr.new .actions > ul li.search_area{
            flex: 1 1 auto;
        }
        .result_wpr.new .actions > ul li.sort_list{
            margin-left: auto;
            flex: 0 0 auto !important;
        }
        .modal.profile_modal .result_wpr .actions > ul > li.edit{
            flex: 0 0 auto !important;
        }
    }
</style>
